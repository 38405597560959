.App {
  text-align: center;
}

.App-header {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  font-size: calc(10px + 2vmin);
  color: white;
}

.lang {
  margin-top: 10px;
  width: 100%; 
  max-width: 20rem;
  display: inline-block;
}

.lang img {
  float: right;
  cursor: pointer;
}

.cleo {
  margin-top: 10px;
}

.cleo img {
  max-width: 20rem;
}

.phone {
  margin-bottom: 30px;
}

.phone a {
  color: #9c9c9c;
}

.social {
  margin-bottom: 2rem;
}

.social .ig {
  cursor: pointer;
}